import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import Img from "gatsby-image";
import PropTypes from "prop-types";
import React from "react";
import ReactMarkdown from "react-markdown";
import Breadcrumbs from "../components/Breadcrumbs";
import ContactForm from "../components/Contactformulier";
import Layout from "../components/layout";
import RelatedCases from "../components/RelatedCases";
import SEO from "../components/seo";

export const ServicesCategoryPageTemplate = ({ post }) => {
  return (
    <div className="container mx-auto px-6  text-white flex flex-col  ">
      <div className="max-w-4xl mb-12 ">
        <Breadcrumbs
          breadcrumbs={[
            {
              name: "Services",
              slug: "/services/",
            },
            {
              name: post.id,
            },
          ]}
        />

        <p className="mt-6 leading-relaxed sm:text-lg md:text-2xl xl:text-2xl text-white">
          {post.lead_paragraph}
        </p>
        <ReactMarkdown
          className="text-lg mb-2 font-light pt-6 netlify-html text-white "
          source={
            post.description
              ? post.description.split(`](assets`).join(`](/assets`)
              : ""
          }
        />
      </div>

      <div className="mt-4 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-4">
        {post.services &&
          post.services.map((service) => {
            if (!service) {
              return undefined;
            }
            return (
              <div
                className="flex flex-col  items-center justify-center w-full"
                key={service.id}
              >
                <div className="flex flex-col w-full h-full">
                  <div className="flex flex-col flex-grow">
                    {service.background && (
                      <BackgroundImage
                        Tag="section"
                        fluid={service.background.childImageSharp.fluid}
                        style={{
                          backgroundBlendMode: "multiply",
                          backgroundPosition: "center",
                        }}
                        aria-label="Fullscreen Background"
                        className=" w-full h-48 flex flex-col justify-center content-center items-center mb-3"
                      >
                        <>
                          {service.thumbnail &&
                            service.thumbnail.childImageSharp && (
                              <Img
                                alt={service.id}
                                fixed={service.thumbnail.childImageSharp.fixed}
                                title={service.id}
                              />
                            )}
                          <h3 className="text-lg text-white font-bold mb-3 font-display ">
                            {service.id}
                          </h3>
                        </>
                      </BackgroundImage>
                    )}
                  </div>

                  <div className=" flex-grow-0 text-white flex flex-col justify-start content-start items-start text-center mt-2 mb-8 ">
                    <Link
                      className="bg-transparent hover:bg-gladior-pink  text-white  font-semibold hover:text-white py-2 px-4 border border-gladior-pink hover:border-transparent rounded"
                      to={service.fields.slug}
                    >
                      Meer informatie
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
      </div>

      {post.members && post.members.length > 0 && (
        <>
          <h3 className="mt-10  text-2xl sm:text-3xl md:text-3xl xl:text-3xl leading-tight  text-white font-bold mb-3 font-display ">
            {post.our_team}
          </h3>

          <div className=" mt-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-3">
            {post.members.map((member) => {
              if (!member || !member.image) {
                return undefined;
              }
              return (
                <figure className="relative  group" key={member.id}>
                  <Img
                    className="shadow-xl hover:shadow-2xl"
                    fluid={member.image.childImageSharp.fluid}
                    imgStyle={{ objectFit: "contain" }}
                    style={{ height: "100%" }}
                  />
                  <figcaption className="absolute text-lg -mt-16 text-white px-4 hidden group-hover:block">
                    <div>
                      <h2 className="font-semibold font-display text-base sm:text-xl">
                        {member.id}
                      </h2>
                    </div>
                    <div>
                      <p className="text-sm sm:text-base">{member.function}</p>
                    </div>
                  </figcaption>
                </figure>
              );
            })}
          </div>
        </>
      )}

      {post.related_cases && post.related_cases.length > 0 && (
        <>
          <h3 className="mt-20 mb-10 text-2xl sm:text-3xl md:text-3xl xl:text-3xl leading-tight  text-white font-bold mb-3 font-display ">
            Our related cases
          </h3>
          <RelatedCases relatedCases={post.related_cases} />
        </>
      )}
    </div>
  );
};

const ServicesCategoryPage = ({ data }) => {
  const { serviceCategoryYaml: post } = data;

  return (
    <>
      <SEO
        description={post.seo.meta_description}
        pathname={post.path}
        title={post.seo.meta_title}
      />
      <Layout heroBackgroundImage={post.hero_background_image} title={post.id}>
        <main className="bg-gladior-dark text-white ">
          <section className="container mx-auto px-6 py-10  max-w-7xl ">
            <ServicesCategoryPageTemplate post={post} />
          </section>
          <ContactForm />
        </main>
      </Layout>
    </>
  );
};

ServicesCategoryPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ServicesCategoryPage;

export const serviceCategoryPageQuery = graphql`
  query ServicesCategoryPage($id: String!) {
    serviceCategoryYaml(id: { eq: $id }) {
      id
      lead_paragraph
      seo {
        meta_description
        meta_title
      }
      hero_background_image {
        childImageSharp {
          fluid(quality: 90, maxWidth: 4160) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      related_cases {
        about_customer
        body
        description
        id
        lead_paragraph
        quote {
          organisation
          person
          quote
        }
        seo {
          meta_description
          meta_title
        }
        services
        subtitle
        templateKey
        title
        fields {
          slug
        }
        cover_image {
          childImageSharp {
            fixed(height: 256) {
              ...GatsbyImageSharpFixed_withWebp_tracedSVG
            }
          }
        }
      }
      our_team
      members {
        id
        function
        image {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
      description
      services {
        caption
        id
        description
        lead_paragraph
        thumbnail {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        background {
          childImageSharp {
            fixed(height: 100) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
        title
        fields {
          slug
        }
        # service_items {
        #   body
        #   id

        #   lead_paragraph
        #   fields {
        #     slug
        #   }
        # }
      }
    }
  }
`;
